import { render, staticRenderFns } from "./other_photos.vue?vue&type=template&id=38fd4f6a&scoped=true&"
import script from "./other_photos.vue?vue&type=script&lang=js&"
export * from "./other_photos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38fd4f6a",
  null
  
)

export default component.exports